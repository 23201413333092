import http from "../common/http";

class ProductService {

  findAllProducts(category, page, limit, search) {
    return http.request('get', `/product/find-all?category=${category}&page=${page}&limit=${limit}&search=${search}`);
  }

  findOneProduct(id) {
    return http.request('get', `/product/find-one?id=${id}`);
  }

  findCategories() {
    return http.request('get', `/product/category/find-all`);
  }

  createProductReview(data) {
    return http.request('post', `/product/review/create`, data);
  }

  updateProductReview(data) {
    return http.request('post', `/product/review/update`, data);
  }

  findAllProductReviews(productId) {
    return http.request('get', `/product/review/find-all?productId=${productId}`);
  }

  findOneProductReview(userId, productId) {
    return http.request('get', `/product/review/find-one?userId=${userId}&productId=${productId}`);
  }

  becomeAnAffiliate(data) {
    return http.request('post', `/product/affiliate`, data);
  }

  findOneAffiliate(userId, businessId, productId) {
    return http.request('get', `/product/affiliate/find-one?userId=${userId}&businessId=${businessId}&productId=${productId}`);
  }

  findAffiliateByRef(ref) {
    return http.request('get', `/product/affiliate/find-ref?ref=${ref}`);
  }
}

export default new ProductService();
