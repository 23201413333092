import PostService from '../../services/post.service'

export const state = {
    posts: [],
    postComments: [],
};

export const mutations = {

    CREATE_POST(state, value) {
        state.posts.push(value)
    },

    UPDATE_POST(state, value) {
        if(value) {
            let index = state.posts.findIndex(post => post.id == value.id)
            state.posts[index] = value
        }
    },

    FIND_ALL_POSTS(state, value) {
        state.posts = value
    },

    DELETE_POST(state, value) {
        let index = state.posts.findIndex((res) => res.id == value)
        state.posts.splice(index, 1)
    },  

    LIKE_POST(state, value) {
        let index = state.posts.findIndex((res) => res.id == value.postId)
        state.posts[index].userHasLiked = value.userHasLiked
        if(value.userHasLiked) {
            state.posts[index].totalLikes = state.posts[index].totalLikes + 1 
        }
        else {
            state.posts[index].totalLikes = state.posts[index].totalLikes - 1
        }
    },

    ADD_POST_COMMENT(state, payload) {
        if(payload.commentId) {
            let index = state.postComments.findIndex(comment => comment.id == payload.commentId)
            state.postComments[index].replies = payload.result.replies || []
        }
        else {
            if(!payload.result.replies) { 
                payload.result.replies = []
            }
           state.postComments.unshift(payload.result) 
        }
        const postIndex = state.posts.findIndex(post => post.id == payload.postId)
        state.posts[postIndex].totalComments = state.posts[postIndex].totalComments + 1
    },

    UPDATE_POST_COMMENT(state, payload) {
        let index = state.postComments.findIndex(comment => comment.id == payload.commentId)
        state.postComments[index] = payload.result 
    },

    FIND_POST_COMMENTS(state, value) {
        state.postComments = value
    },

    DELETE_POST_COMMENT(state, value) {
        let index = state.comments.findIndex((res) => res.id == value)
        state.postComments.splice(index, 1)
    },
};

export const actions = {

    async createPost({commit}, payload) {
        try {
            let response = await PostService.createPost(payload)
            commit('CREATE_POST', response[0])
            return response
        }
        catch(error) {
            //console.log('create post action', error)
        }
    },
    
    async updatePost({commit}, payload) {
        try {
            let response = await PostService.updatePost(payload)
            commit('UPDATE_POST', response[0])
            return response
        }
        catch(error) {
            //console.log('update post action', error)
        }
    },

    async findAllPosts({ commit }, payload) {
        try {
            const { userId, businessId } = payload
            let response = await PostService.findAllPosts(userId, businessId)
            commit('FIND_ALL_POSTS', response)
            //console.log('find all response', response)
            return response
        }
        catch(error) {
            console.log('find all posts action', error)
            throw error
        }
    },

    async deletePost({commit}, payload) {
        try {
            const { userId, postId } = payload
            let response = await PostService.deletePost(userId, postId)
            commit('DELETE_POST', postId)
            return response
        }
        catch(error) {
            //console.log('delete post action', error)
        }
    },

    setPosts({commit}, payload) {
        commit('FIND_ALL_POSTS', payload)
    },

    async likePost({commit}, payload) {
        try {
            let response = await PostService.likePost(payload)
            //console.log('likePost', response)
            const props = {
                postId: payload.postId,
                userHasLiked: response
            }
            commit('LIKE_POST', props)
            return response
        }
        catch(error) {
            //console.log('update post action', error)
        }
    },

    async createPostComment({commit}, payload) {
        try {
            let response = await PostService.createPostComment(payload)
            payload.result = response[0]
            commit('ADD_POST_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('create post comment action', error)
        }
    },

    async updatePostComment({commit}, payload) {
        try {
            let response = await PostService.updatePostComment(payload)
            //console.log('updatePostComment', response)
            payload.result = response[0]
            commit('UPDATE_POST_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('update post comment action', error)
        }
    },

    async findAllPostComments({ commit }, postId) {
        try {
            let response = await PostService.findAllPostComments(postId)
            commit('FIND_POST_COMMENTS', response)
            return response
        }
        catch(error) {
            //console.log('find all post comments action', error)
        }
    },

    async deletePostComment({commit}, payload) {
        try {
            const { postId, commentId } = payload
            let response = await PostService.deletePostComment(postId, commentId)
            commit('DELETE_POST_COMMENT', commentId)
            return response
        }
        catch(error) {
            //console.log('delete post comment action', error)
        }
    },
};
